import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyBCPw8NOEhAIfwDTbcMBKEccBAFOJVys2M",
  authDomain: "fabereye-3ceee.firebaseapp.com",
  projectId: "fabereye-3ceee",
  storageBucket: "fabereye-3ceee.appspot.com",
  messagingSenderId: "428971788437",
  appId: "1:428971788437:web:d5ebfbcfcdf0ae197b739c",
  measurementId: "G-HZ3DSK72FR"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);