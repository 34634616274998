import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { signOut } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import QuizListElement from "./QuizListElement";
function MainPanel() {
    const [quizList, setQuizList] = useState([]);
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (!user) {
                //not logged in
                window.location.replace("/login")
            }
            let userDocref = doc(db, "users", auth.currentUser.uid)
            getDoc(userDocref).then((resultdoc)=>{
                let docExists = resultdoc.exists();
                if(!docExists){
                    setDoc(userDocref, {
                        "userQuizes": []
                    })
                }
                else{
                    console.log(resultdoc.data()["userQuizes"]);
                    setQuizList(resultdoc.data()["userQuizes"])
                }

            })

        });
    
        // Cleanup the listener on component unmount
        return () => unsubscribe();
      }, []);
    function HandleLogOutPress() {
        signOut(auth)
    }
    return (
        <div>
            <p>Main Panel</p>
            <ul>
                {quizList.map((element)=>{
                    return(
                        <li><QuizListElement quiz={element}></QuizListElement></li>
                    )
                })}
            </ul>
            <button onClick={HandleLogOutPress}>Log out</button>
        </div>

    )
}

export default MainPanel