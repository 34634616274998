import { useEffect } from "react";
import { useState } from "react";
export default function QuizQuestion({question, userAnwsered, anwsers, userAnwser, setUserAnwser, anwserColors}) {
    const [radioState, seteRadioState] = useState([]);
    useEffect(()=>{

        let dirtyAnwserList = [question["Option 1 Text"], question["Option 2 Text"], question["Option 3 Text"], question["Option 4 Text"], question["Option 5 Text"]]
        let clearedAnwsersList = [];
        for (let i = 0; i<dirtyAnwserList.length; i++){
            if (dirtyAnwserList[i]!=""){
                clearedAnwsersList.push(dirtyAnwserList[i]);
            }
        }
        seteRadioState([false, false, false, false, false]);
    }, [question])

    
    return(
        <div className="anwsersBox">
            <p>{question["Question Text"]}</p>
            {anwsers.map((item, i)=>{
                if(item != ""){
                    return(
                        <label  className="anwser" style={{"color": anwserColors[i]}}>
                            <input disabled={userAnwsered} type="radio" name="anwser" value={i} checked={radioState[i]} onChange={(e)=>{
                                setUserAnwser(i);
                                let newAnsers = [false, false, false, false, false];
                                newAnsers[i] = true;
                                seteRadioState(newAnsers);
                            }}></input>{item}
                        </label>
                    )
                }
            })}            
        </div>
    )
}