import { db } from "../firebase"
import { getDocs, collection } from "firebase/firestore"
import { useState } from 'react'
import { Link } from "react-router-dom"
export default function Store() {
    const [storeQuizDocs, SetStoreQuizDocs] = useState([])
    useState(()=>{
        (async ()=>{
            let newDocsSnapshsot = await getDocs(collection(db, "store"));
            let newDocs = [];
            newDocsSnapshsot.forEach((doc)=>{
                newDocs.push(doc);
            })
            console.log(newDocs);
            SetStoreQuizDocs(newDocs)
        })()
    }, []) 
    console.log(storeQuizDocs);
    if(storeQuizDocs.length == 0){
        return(
            <div>Loading...</div>
        )
    }
    return(
        <div className="storeContainer">
            {
                storeQuizDocs.map((doc)=>{
                    let data = doc.data();
                    let id = doc.id;
                    return(
                        <div>
                            <Link to={`/buy/${id}`}>{id} - {data["price"]}</Link>
                        </div>
                    )
                })
            }
        </div>
    )
}