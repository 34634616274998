import { useState } from "react"
import { Link } from "react-router-dom"
import { linkWithPopup, signInWithEmailAndPassword } from "firebase/auth"
import { auth } from '../firebase.js'
import { useEffect } from "react"
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth"
function LoginForm() {
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                //logged in
                window.location.replace("/")
            }
        });
    
        // Cleanup the listener on component unmount
        return () => unsubscribe();
      }, []);

    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")

    function HandleLoginPress() {
        signInWithEmailAndPassword(auth, login, password).then(() => {
            window.location.reload(true);
        })
        .catch((e)=>{
            console.log(e);
        })
    }
    function HandleGoogleLoginPress() {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)
            .then(() => {
                window.location.reload(true);
            }).catch((error) => {
                console.log(error);
            });
    }
    function HandleFacebookLoginPress() {
        const provider = new FacebookAuthProvider()
        signInWithPopup(auth, provider)
        .then(() => {
            window.location.reload(true);
        }).catch((error) => {
            console.log(error);
        });
    }
    function HandleMicrosoftLoginPress() {
        const provider = new OAuthProvider("microsoft.com")
        linkWithPopup(auth.currentUser, provider)
        .then(() => {
            window.location.reload(true);
        }).catch((error) => {
            console.log(error);
        });
    }
    return (
        <div>
            <input value={login} type="text" onChange={(e) => { setLogin(e.target.value) }}></input>
            <input value={password} type="password" onChange={(e) => { setPassword(e.target.value) }}></input>
            <button onClick={HandleLoginPress}>Log in</button>
            <button onClick={HandleGoogleLoginPress}>Log in with Google</button>
            <button onClick={HandleFacebookLoginPress}>Log in with Facebook</button>
            <button onClick={HandleMicrosoftLoginPress}>Log in with Microsoft</button>
            <Link to="/register">Register instead</Link>
        </div>
    )
}

export default LoginForm