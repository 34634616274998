import { useState, useEffect } from "react";
import QuizQuestion from "./QuizQuestion";
import { useParams } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import QuizFinal from "./QuizFinal";
export default function QuizSite({ }) {
    const { quizName } = useParams()
    const [quizConfig, setQuizConfig] = useState({});
    const [questions, setQuestions] = useState([]);
    const [currentQuestionID, setCurrentQuestionID] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [userQuestionAnwsers, setUserQuestionAnwsers] = useState(new Map());
    const [userAnwser, setUserAnwser] = useState(null);
    const [feedback, setFeedback] = useState("");
    const [feedbackColor, setFeedbackColor] = useState("black");
    const [isOptionCorrectList, setIsOptionCorrectList] = useState([]);
    const [hasUserAnwsered, setHasUserAnwsered] = useState(false);
    const [bookmarks, setBookmarks] = useState([]); // [questionID, questionID, questionID...
    const [anwsers, setAnwsers] = useState()
    const [anwserColors, setAnwserColors] = useState([]);
    const [isQuizCompleted, setIsQuizCompleted] = useState(false);
    const [bookmarkSumitButtonDisabled, setBookmarkSubmitButtonDisabled] = useState(false);
    const [userFinished, setUserFinished] = useState(true);
    useEffect(() => {
        (async () => {
            if (quizName) {
                const configDownloadUrl = getDownloadURL(ref(storage, `quizes/${quizName}/quizConfig.json`));
                const dataDownloadUrl = getDownloadURL(ref(storage, `quizes/${quizName}/quizData.json`));
                let newConfig = await ((await fetch(await configDownloadUrl)).json());
                let data = await  ((await fetch(await dataDownloadUrl)).json());
                let questionsAmount = newConfig["questionsAmount"];
                console.log(questionsAmount);
                let i = 0;
                let questionIndexes = [];
                let newQuestions = [];
                while (i < questionsAmount) {
                    let questionIndex = Math.floor(Math.random() * (data.length + 1))
                    console.log(i);
                    console.log(questionIndex);
                    console.log(questionIndexes);
                    if (!questionIndexes.includes(questionIndex)) {
                        questionIndexes.push(questionIndex);
                        newQuestions.push(data[questionIndex]);
                        i++;
                    }
                }
                setQuestions(newQuestions);
                setQuizConfig(newConfig);
            }
        })()
    }, []);
    useEffect(() => {
        let newcurrentQuestion = questions[currentQuestionID]; 
        setCurrentQuestion(newcurrentQuestion);
        if(newcurrentQuestion != undefined){
            setAnwsers([newcurrentQuestion["Option 1 Text"], newcurrentQuestion["Option 2 Text"], newcurrentQuestion["Option 3 Text"], newcurrentQuestion["Option 4 Text"], newcurrentQuestion["Option 5 Text"]])
            setIsOptionCorrectList([newcurrentQuestion["Option 1 Correct"], newcurrentQuestion["Option 2 Correct"], newcurrentQuestion["Option 3 Correct"], newcurrentQuestion["Option 4 Correct"], newcurrentQuestion["Option 5 Correct"]])
        }
    }, [currentQuestionID, questions])
    function AddBookmark(questionID) {
        console.log(bookmarks);
        if(!bookmarks.includes(questionID)){
            setBookmarks([...bookmarks, questionID]);
        }
    }
    function GetCorrectAnwsersList(questionID) {
        let question = questions[questionID];
        let correctAnwsersList = [question["Option 1 Correct"], question["Option 2 Correct"], question["Option 3 Correct"], question["Option 4 Correct"], question["Option 5 Correct"]];
        return correctAnwsersList;
    }
    function Submit(questionID) {
        let newAnwserColors = [];
        let wasUserCorrect = false;
        let question = questions[questionID];
        console.log(userAnwser);
        for(let i = 0; i < anwsers.length; i++){
            newAnwserColors[i] = "black";
        }   
        newAnwserColors[userAnwser] = "red";
        console.log("set" + userAnwser);
        console.log(newAnwserColors);
        if(!wasUserCorrect && isOptionCorrectList[userAnwser]){ //if user was correct at least once
            wasUserCorrect = true;
        }
        for(let i = 0; i < isOptionCorrectList.length; i++){
            if(isOptionCorrectList[i]){
                newAnwserColors[i] = "green";
            }
        } 
        if(wasUserCorrect){
            setFeedback(question["Question Feedback"]);
            setFeedbackColor("green");
        }
        else{
            setFeedback(question["Incorrect Feedback"]);
            setFeedbackColor("red");
        }
        setAnwserColors(newAnwserColors);
        setUserQuestionAnwsers(new Map(userQuestionAnwsers.set(questionID, userAnwser)));
        setHasUserAnwsered(true)
        setBookmarks(bookmarks.filter((item)=>{return item != questionID}));
    }
    function HandleNextButtonPress() {
        if(userQuestionAnwsers.get(currentQuestionID) == undefined || userQuestionAnwsers.get(currentQuestionID) == null || userQuestionAnwsers.get(currentQuestionID) == []){
            AddBookmark(currentQuestionID);
        }
        let newAnwserColors = [];
        for(let i = 0; i < anwsers.length; i++){
            newAnwserColors[i] = "black";
        }
        setAnwserColors(newAnwserColors);
        setCurrentQuestionID(currentQuestionID + 1);
        setUserAnwser(null);
        setFeedback("");
        setHasUserAnwsered(false);
    }
    function HandleAddBookmarkButtonPress() {
        AddBookmark(currentQuestionID);
    }
    function HandleSubmitButtonPress() {
        Submit(currentQuestionID);
    }
    function HandleBookmarksSubmitButtonPress() {
        Submit(currentQuestionID);
        setBookmarkSubmitButtonDisabled(true);
        console.log(bookmarks);
    }
    function ChangeCurrentBookmarkQuestion(item) {
        let newColors = [];
        for(let i = 0; i < anwsers.length; i++){
            newColors[i] = "black";
        }
        setAnwserColors(newColors);
        setHasUserAnwsered(false);
        setCurrentQuestionID(item);
        setBookmarkSubmitButtonDisabled(false);
    }
    if (currentQuestionID >= quizConfig["questionsAmount"] || isQuizCompleted) {
        if(!isQuizCompleted){
            setIsQuizCompleted(true);
        }
        if(bookmarks.length == 0 && userFinished){
            let score = 0;
            for(let i = 0; i < questions.length; i++){
                let correctAnwsersList = GetCorrectAnwsersList(i);
                console.log(userQuestionAnwsers);
                console.log(correctAnwsersList[userQuestionAnwsers.get(i)]);
                if(correctAnwsersList[userQuestionAnwsers.get(i)]){
                    score++;
                }
                console.log(correctAnwsersList);
            }
            return (
                <QuizFinal score={score} amountOfQuestions={questions.length}></QuizFinal>
            )
        }
        else{
            if(userFinished){
                setUserFinished(false);
            }
            return(
                <div>
                    <h1>Bookmarks</h1>
                    <ul>
                        {bookmarks.map((item, i)=>{
                            return(
                                <li><button onClick={()=>{ChangeCurrentBookmarkQuestion(item)}}>Question {item + 1}</button></li>
                            )
                        })}
                    </ul>
                    {currentQuestionID != -1 && currentQuestion != undefined ?
                        <QuizQuestion question={currentQuestion} anwsers={anwsers} userAnwsered={hasUserAnwsered} userAnwser={userAnwser} setUserAnwser={setUserAnwser} anwserColors={anwserColors}></QuizQuestion> 
                        : <div></div>
                    }
                    <button onClick={HandleBookmarksSubmitButtonPress} disabled={bookmarkSumitButtonDisabled}>Submit</button>
                    {bookmarks.length == 0 ? <button onClick={()=>{setUserFinished(true)}}>See results</button> : <div></div>}
                    <p style={{"color": feedbackColor}}>{feedback}</p>
                </div>
            )
        }
    }

    else if (currentQuestion != undefined && currentQuestion != {} && anwsers != undefined) {
        return (
            <div>
                <QuizQuestion question={currentQuestion} anwsers={anwsers} userAnwsered={hasUserAnwsered} userAnwser={userAnwser} setUserAnwser={setUserAnwser} anwserColors={anwserColors}></QuizQuestion>
                <p style={{"color": feedbackColor}}>{feedback}</p>
                <button onClick={HandleSubmitButtonPress} disabled={!userAnwser == null || hasUserAnwsered}>Submit</button>
                <button onClick={HandleNextButtonPress}>Next</button>
                <button onClick={HandleAddBookmarkButtonPress}>Bookmark</button>
            </div>
        )
    }
    else {
        return (
            <div>Loading...</div>
        )
    }


}