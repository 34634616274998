import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db, auth } from "../firebase";
import { doc, getDoc, collection, addDoc, query, where, getDocs, onSnapshot } from "firebase/firestore";
export default function QuizStoreSite() {
    const quizName = useParams().quizName;
    const [quizDetails, setQuizDetails] = useState({});
    useEffect(() => {
        (async ()=>{
            // get doc with id of quizName from quizDetails collection
            const docRef = doc(db, "quizDetails", quizName);
            const docSnap = await getDoc(docRef);
            setQuizDetails(docSnap.data());
        })()
    }, [])
    useEffect(() => {
        console.log(quizDetails);
        console.log(quizName);
    }, [quizDetails])
    async function HandleBuyButtonPress() {
        console.log(quizDetails);
        const productColRef = collection(db, `products/${quizDetails["productID"]}/prices`);
        console.log(`products/${quizDetails["productID"]}/prices`);
        const q = query(productColRef);
        const querySnapshot = await getDocs(q);
        const priceDoc = querySnapshot.docs[0];
        console.log(querySnapshot.docs[0]);

        const checkoutSessionsColRef = collection(db, `customers/${auth.currentUser.uid}/checkout_sessions`)
        const checkoutSession = await addDoc(checkoutSessionsColRef, {
            mode: "payment",
            price: priceDoc.id, // One-time price created in Stripe
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });
        onSnapshot(checkoutSession, (doc)=>{
            const data = doc.data();
            if(data["url"]){
                window.location.assign(data["url"]);
            }
        })
    }

    if(quizDetails.price == undefined){
        return(
            <div>Loading...</div>
        )
    }
    else{
        console.log(quizDetails);
        return (
            <div>
                <h1>{quizName}</h1>
                <p>price - {quizDetails["price"]}$</p>
                <p>{quizDetails["description"]}</p>
                <ul>
                {
                    quizDetails["categories"].map((category)=>{
                        return(
                            <li>{category}</li>
                        )
                    })
                }
                </ul>
                <button onClick={HandleBuyButtonPress}>Buy</button>
            </div>
        )
    }
}