import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginForm from './Elements/LoginForm';
import MainPanel from './Elements/MainPanel';
import RegisterForm from './Elements/RegisterForm'
import { createContext } from 'react';
import QuizSite from './Elements/QuizSite';
import QuizStoreSite from './Elements/QuizStoreSite';
import Store from './Elements/Store';
function App() {
  const userContext = createContext()
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
          <Route index element={<MainPanel />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="register" element={<RegisterForm/>}/>
          <Route path="store" element={<Store/>}></Route>
          <Route path="buy">
            <Route path=":quizName" element={<QuizStoreSite/>}/>
          </Route>
          <Route path='quiz' >
            <Route path=':quizName' element={<QuizSite/>}></Route>
            {/* <Route path="*" element={<NoPage />}/> */}
          </Route>
          {/* <Route path="*" element={<NoPage />} /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default App;
