import { useState } from "react"
import { Link } from "react-router-dom"
import { auth, db } from "../firebase"
import { doc, setDoc } from "firebase/firestore"
import { createUserWithEmailAndPassword } from "firebase/auth"
function RegisterForm() {
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    function HandleRegisterButtonPress() {
        if(password == confirmPassword){
            createUserWithEmailAndPassword(auth, login, password).then((userCredential)=>{
                window.location.replace("/")
            }).catch((error)=>{
                setErrorMessage(error.message)
            })
        }
        else{
            setErrorMessage("Passwords don't match")
        }

    }
    return(
        <div>
            <input value={login} type="text" onChange={(e)=>{setLogin(e.target.value)}}></input>
            <input value={password} type="password" onChange={(e)=>{setPassword(e.target.value)}}></input>
            <input value={confirmPassword} type="password" onChange={(e)=>{setConfirmPassword(e.target.value)}}></input>
            <button onClick={HandleRegisterButtonPress}>Register</button>
            <Link to="/login">Log in instead</Link>
            <p>{errorMessage}</p>
        </div> 
    )
}

export default RegisterForm